import RoutesWrapper from "./routes";
import { App as AntdApp } from "antd";
import AntdConfig from "./theme/antd";
import { MotionLazy } from "./components/animate/motion-lazy";
// import AuthContextProvider from "./context";

function App() {
  return (
    <AntdConfig>
      <AntdApp>
        <MotionLazy>
          {/* <AuthContextProvider> */}
          <RoutesWrapper />
          {/* </AuthContextProvider> */}
        </MotionLazy>
      </AntdApp>
    </AntdConfig>
  );
}
export default App;
