import { userProps } from "../interface";
import { SettingsType } from "../interface"; // Import the SettingsType interface

export function getStoredUser() {
  const storedUser = localStorage.getItem("user");
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user: userProps) {
  localStorage.setItem("user", JSON.stringify(user));
}

// STUB: save login token to local storage
export function setLoginToken(token: string) {
  localStorage.setItem("token", JSON.stringify(token));
}

// STUB: get login token from local storage
export function getLoginToken() {
  const storedToken = localStorage.getItem("token");
  return storedToken ? JSON.parse(storedToken) : null;
}
export function getToken() {
  return localStorage.getItem("token");
}

export function getStoredSettings() {
  const storedSettings = localStorage.getItem("settings");
  return storedSettings ? JSON.parse(storedSettings) : null;
}

export function setStoredSettings(settings: SettingsType) {
  localStorage.setItem("settings", JSON.stringify(settings));
}
