import { Spin } from "antd";
import like from "../../assets/like.png";
import { LoadingOutlined } from "@ant-design/icons";

export function CircleLoading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />

      <img
        src={like}
        alt="logo"
        style={{
          position: "absolute",
          bottom: 40,
          width: "40px",
          height: "40px",
        }}
      />
    </div>
  );
}
