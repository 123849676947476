import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context";
import { getLoginToken, setLoginToken } from "../../storage";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorAlert, successAlert, toastOptions } from "../../utils";
import { toast } from "react-toastify";
import { axiosInstance } from "../../axios-Instance";
import { queryKeys } from "../../react-query/constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface DownlinesResponse {
  downlines: any;
}

const SERVER_ERROR = "There was an error contacting the server.";

export function useLogin() {
  const authCtx = useContext(AuthContext);
  return useMutation({
    mutationFn: async (formData) => {
      const data = await axiosInstance({
        url: "/login",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      return data.data;
    },
    onSuccess: (data) => {
      successAlert("Login Successful");
      setLoginToken(data.token);
      authCtx.authenticate(data.token);
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
}

// export function useLogout() {
//   return useMutation({
//     mutationFn: async (formData) => {
//       try {
//         console.log("Sending logout request...");
//         const response = await axiosInstance({
//           url: "auth/logout",
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${getLoginToken()}`,
//           },
//         });
//         return response.data;
//       } catch (error) {
//         throw error;
//       }
//     },
//     onSuccess: (data) => {
//       setLoginToken("");
//     },
//     onError: (error: any) => {
//       const err = error?.response?.data?.error
//         ? error?.response?.data?.error
//         : SERVER_ERROR;
//       console.error("Logout error:", err);
//       toast.error(err, toastOptions);
//     },
//   });
// }

async function logout() {
  const data = await axiosInstance({
    url: "auth/logout",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return data?.data;
}

export function useLogout() {
  const authCtx = useContext(AuthContext);
  // const navigate = useNavigate();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: () => logout(),
    onSuccess: (data) => {
      setLoginToken("");
      authCtx.authenticate("");
      // navigate(`/login`);
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

async function refreshToken() {
  const data = await axiosInstance({
    url: "/auth/refresh-token",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return data?.data;
}

export function useRefreshToken() {
  const authCtx = useContext(AuthContext);
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: () => refreshToken(),
    onSuccess: (data) => {
      console.log("Refresh token success:", data);
      setLoginToken(data.token);
      authCtx.authenticate(data.token);
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

// export function useRefreshToken() {
//   const authCtx = useContext(AuthContext);
//   return useMutation({
//     mutationFn: async (formData) => {
//       const data = await axiosInstance({
//         url: "/auth/refresh-token",
//         method: "POST",
//         data: formData,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${getLoginToken()}`,
//         },
//       });

//       return data.data;
//     },
//     onSuccess: (data) => {
//       setLoginToken(data.token);
//       authCtx.authenticate(data.token);
//     },
//     onError: (error: any) => {
//       const err = error?.response?.data?.error
//         ? error?.response?.data?.error
//         : SERVER_ERROR;
//       toast.error(err, toastOptions);
//     },
//   });
// }

async function userRegister(formData: any) {
  const data = await axiosInstance({
    url: "/users/new",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data?.data;
}

export function useRegister() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => userRegister(formData as any),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users]);
      successAlert("Registration Successful");
      navigate(`/verify-account`);
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

async function verifyRecaptcha(formData: any) {
  const data = await axiosInstance({
    url: "/auth/createAssessment",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data?.data;
}

export function useVerifyRecaptcha() {
  // const navigate = useNavigate();
  const [responses, setResponses] = useState<any>(null);
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => verifyRecaptcha(formData as any),
    onSuccess: (data) => {
      const captchaData = data;
      if (captchaData) {
        setResponses(captchaData);
      }
      // successAlert("Registration Successful");
      // navigate(`/verify-account`);
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset, responses };
}

async function forgotPassword(formData: any) {
  const data = await axiosInstance({
    url: "/users/generatePasswordLink",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data?.data;
}

export function useForgotPassword() {
  const navigate = useNavigate();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => forgotPassword(formData as any),
    onSuccess: () => {
      navigate(`/login`);
      successAlert("Check your Mailbox for instructions");
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

async function resetPassword(formData: any) {
  const data = await axiosInstance({
    url: "/users/resetPassword",
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data?.data;
}

export function useResetPassword() {
  const navigate = useNavigate();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => resetPassword(formData as any),
    onSuccess: () => {
      navigate(`/login`);
      successAlert("Password reset Successful");
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

async function retokenize(formData: any) {
  const data = await axiosInstance({
    url: "/users/newToken",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data?.data;
}

export function useRetokenize() {
  const navigate = useNavigate();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => retokenize(formData as any),
    onSuccess: () => {
      navigate(`/login`);
      successAlert("Check your email for instruction");
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

async function updatePassword(formData: any) {
  const data = await axiosInstance({
    url: "/users/updateData",
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data?.data;
}

export function useUpdatePassword() {
  const navigate = useNavigate();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => updatePassword(formData as any),
    onSuccess: () => {
      navigate(`/`);
      successAlert("Password Updated Successful");
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

async function updateMe(formData: any) {
  const data = await axiosInstance({
    url: "users/auth/me",
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `${getLoginToken()}`,
    },
  });

  return data?.data;
}

export function useUpdateMe() {
  const queryClient = useQueryClient();
  const { mutate, isError, error, isSuccess, reset, data } = useMutation({
    mutationFn: (formData) => updateMe(formData as any),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.me]);
    },

    onError: (error) => {
      errorAlert(error);
    },
  });
  return { mutate, isError, error, isSuccess, reset, data };
}

async function getMe() {
  const data = await axiosInstance({
    url: "/users/auth/me",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return data?.data;
}

export function useGetMe() {
  const fallback = { user: undefined };
  const { data = fallback } = useQuery({
    queryKey: [queryKeys.user],
    queryFn: () => getMe(),
    onSuccess: (data) => {
      if (!data.user) {
      }
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return data ?? fallback;
}

async function getNotifications(page = 1, limit = 100) {
  const data = await axiosInstance({
    url: `/users/notifications?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return data?.data;
}

export function useGetNotifications(page = 1, limit = 100) {
  const fallback = {};
  const { data = fallback } = useQuery({
    queryKey: [queryKeys.notification, page, limit],
    queryFn: () => getNotifications(page, limit),
    onSuccess: () => {},
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return data;
}

export function useGetMyDownlines(
  offset: any,
  pageSize: any
): DownlinesResponse {
  const [downlines, setDownlines] = useState<DownlinesResponse>({
    downlines: { data: [] },
  });

  useEffect(() => {
    const fetchDownlines = async () => {
      try {
        const response = await axiosInstance({
          url: `/users/downlines?status=active&offset=${offset}&limit=${pageSize}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLoginToken()}`,
          },
        });
        setDownlines({
          downlines: { data: response.data || [] },
        });
      } catch (error) {
        console.error("Failed to fetch downlines:", error);
      }
    };

    fetchDownlines();
  }, [offset, pageSize]);

  return downlines;
}

async function changePassword(formData: any) {
  const data = await axiosInstance({
    url: "/auth/changepassword",
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data?.data;
}

export function useChangePassword() {
  const authCtx = useContext(AuthContext);
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => changePassword(formData as any),
    onSuccess: (data) => {
      successAlert("Password Changed Successfully");
      setLoginToken(data.token);
      authCtx.authenticate(data.token);
    },
    onError: (error: any) => {
      const err = error?.response?.data?.error
        ? error?.response?.data?.error
        : SERVER_ERROR;
      toast.error(err, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}

async function getExchangeRate(currencyCode = "") {
  const response = await axios.get(
    `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_EXCHANGE_RATE}/latest/${currencyCode}`
  );

  return response.data;
}

export function useGetExchangeRate(currencyCode: string) {
  const fallback = {};
  const { data = fallback, error } = useQuery(
    ["exchangeRate", currencyCode],
    () => getExchangeRate(currencyCode)
  );

  if (error) {
  }

  return data;
}

// Recaptcha

declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        ready(callback: () => void): void;
        execute(siteKey: string, options: any): Promise<string>;
      };
    };
  }
}

export function useRecaptchaScript(siteKey: any) {
  useEffect(() => {
    const scriptId = "recaptcha-script";
    if (document.getElementById(scriptId)) {
      return;
    }
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
    script.id = scriptId;
    document.head.appendChild(script);

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, [siteKey]);
}

export async function executeRecaptcha(action: any, siteKey: any) {
  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(async () => {
      try {
        const token = await window.grecaptcha.enterprise.execute(siteKey, {
          action,
        });
        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  });
}
