import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getStringItem } from "../utils/storage";
import de_DE from "./lang/de_DE";
import en_US from "./lang/en_US";
import es_ES from "./lang/es_ES";
import fr_FR from "./lang/fr_FR";
import ja_JP from "./lang/ja_JP";
import pt_BR from "./lang/pt_BR";
import { LocalEnum, StorageEnum } from "../types/enum";

const defaultLng =
  getStringItem(StorageEnum.I18N) || (LocalEnum.en_US as string);
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: defaultLng,
    fallbackLng: "en_US",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      de_DE: { translation: de_DE },
      en_US: { translation: en_US },
      es_ES: { translation: es_ES },
      fr_FR: { translation: fr_FR },
      ja_JP: { translation: ja_JP },
      pt_BR: { translation: pt_BR },
    },
  });

export default i18n;
export const { t } = i18n;
