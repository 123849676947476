import { createContext, useEffect, useState } from "react";
import { queryKeys } from "../react-query/constants";
import { getLoginToken, getStoredUser, setStoredUser } from "../storage";
import { getDecodedJWT, isAuthenticated } from "../utils";
import { useAuthenticatedUser } from "./hooks";
import { useQueryClient } from "@tanstack/react-query";
import { ChildProps, userProps } from "../interface";
import { useLogout } from "../hooks/auth";
import { useNavigate } from "react-router-dom";
import useTokenRefresh from "../utils/RefreshToken";
import { BasePaths, PublicPaths } from "../routes/path";

export const AuthContext = createContext({
  user: undefined as userProps | undefined,
  token: undefined as string | undefined,
  isAuthenticated: false,
  authenticate: (_token: string) => {},
  logout: () => {},
  totalLogout: () => {},
  updateUser: (_data: userProps) => {},
});

function AuthContextProvider({ children }: ChildProps) {
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<userProps | undefined>(undefined);
  const userDetails = useAuthenticatedUser();
  const queryClient = useQueryClient();
  const { mutate } = useLogout();
  // const navigate = useNavigate();

  useTokenRefresh();

  useEffect(() => {
    if (!isAuthenticated()) {
      logout();
    }
  }, []);

  useEffect(() => {
    const data = getLoginToken();
    if (data) {
      setAuthToken(data);
    }
  }, []);

  useEffect(() => {
    const data = getStoredUser();
    if (data && data.user) {
      setUser(data.user);
    }
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.user) {
      setUser(userDetails.user);
    }
  }, [userDetails]);

  function logout() {
    setUser(undefined);
    setAuthToken(undefined);
    localStorage.clear();
    queryClient.invalidateQueries([queryKeys.user]);
    queryClient.removeQueries(); // Optional: removes all queries from cache
    // navigate("/login");
    // window.location.href = `/login`;
    // window.location.href = `${PublicPaths.LOGIN}`;
  }

  // function totalLogout() {
  //   try {
  //     mutate();
  //     queryClient.removeQueries();
  //     logout();
  //   } catch (error) {
  //     console.error("Error during total logout:", error);
  //   }
  // }

  function totalLogout() {
    try {
      mutate(undefined, {
        onSuccess: () => {
          queryClient.removeQueries();
          logout();
        },
        onError: (error) => {
          console.error("Error during mutation:", error);
        },
      });
    } catch (error) {
      console.error("Error during total logout:", error);
    }
  }

  function updateUser(data: userProps) {
    setUser(data);
  }

  function authenticate(data: string) {
    setAuthToken(data);
    const decoded = getDecodedJWT();
    const userPropsObj: userProps = {
      _id: decoded?._id || "",
      firstName: decoded?.firstName || "",
      lastName: decoded?.lastName || "",
      token: decoded?.token || "",
      email: decoded?.email || "",
      id: decoded?.id || "",
      gender: decoded?.gender || "",
      role: decoded?.role || "",
      balance: decoded?.balance || 0,
    };
    setUser(userPropsObj);
    setStoredUser(userPropsObj);
  }

  const value = {
    user: user,
    token: authToken,
    isAuthenticated: !!authToken,
    authenticate: authenticate,
    logout: logout,
    totalLogout: totalLogout,
    updateUser: updateUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
