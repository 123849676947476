import en_US from "antd/locale/en_US";
import es_ES from "antd/locale/es_ES";
import de_DE from "antd/locale/de_DE";
import pt_BR from "antd/locale/pt_BR";
import fr_FR from "antd/locale/fr_FR";
import ja_JP from "antd/locale/ja_JP";
import { useTranslation } from "react-i18next";
import type { Locale as AntdLocal } from "antd/es/locale";
import { LocalEnum } from "../types/enum";

type Locale = keyof typeof LocalEnum;
type Language = {
  locale: keyof typeof LocalEnum;
  icon: string;
  label: string;
  antdLocal: AntdLocal;
};

export const LANGUAGE_MAP: Record<Locale, Language> = {
  [LocalEnum.en_US]: {
    locale: LocalEnum.en_US,
    label: "English",
    icon: "twemoji:flag-united-states",
    antdLocal: en_US,
  },
  [LocalEnum.es_ES]: {
    locale: LocalEnum.es_ES,
    label: "Spanish",
    icon: "twemoji:flag-spain",
    antdLocal: es_ES,
  },
  [LocalEnum.de_DE]: {
    locale: LocalEnum.de_DE,
    label: "German",
    icon: "twemoji:flag-germany",
    antdLocal: de_DE,
  },

  [LocalEnum.pt_BR]: {
    locale: LocalEnum.pt_BR,
    label: "Portuguese",
    icon: "twemoji:flag-for-portugal",
    antdLocal: pt_BR,
  },
  [LocalEnum.fr_FR]: {
    locale: LocalEnum.fr_FR,
    label: "France",
    icon: "twemoji:flag-france",
    antdLocal: fr_FR,
  },

  [LocalEnum.ja_JP]: {
    locale: LocalEnum.ja_JP,
    label: "Japanese",
    icon: "twemoji:flag-japan",
    antdLocal: ja_JP,
  },
};

export default function useLocale() {
  const { i18n } = useTranslation();
  const setLocale = (locale: Locale) => {
    i18n.changeLanguage(locale);
  };
  const locale = (i18n.resolvedLanguage || LocalEnum.en_US) as Locale;
  const language = LANGUAGE_MAP[locale];

  return {
    locale,
    language,
    setLocale,
  };
}
