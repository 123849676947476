export const queryKeys = {
  user: "user",
  me: "me",
  photo: "photo",
  users: "users",
  downlines: "downlines",
  usersRole: "usersRole",
  notification: "notification",
  plans: "plans",
  subscription: "subscription",
  transaction: "transaction",
  earnings: "earnings",
  role: "role",
  payment: "payment",
  paymentMethod: "paymentMethod",
  paymentHistory: "paymentHistory",
  subscriptionHistory: "subscriptionHistory",
  subscriptionPlan: "subscriptionPlan",
  subscriptionPlanHistory: "subscriptionPlanHistory",
  countryCodes: "countryCodes",
};
