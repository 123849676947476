import { Navigate, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../utils";
import { useContext, useEffect, useMemo } from "react";
import { AuthContext } from "../context";
import NotAuthorized from "../modules/NotAuthorized";
interface AuthGuardProps {
  children: React.ReactNode;
  allowedRoles?: string[];
  loggedInUser?: string[];
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children, allowedRoles }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const loggedInUser = useMemo(() => {
    return user;
  }, [user]);

  if (!isAuthenticated) {
    return <Navigate to={"/auth/session-timeout"} replace />;
  }

  // if (!loggedInUser) {
  //   return <Navigate to={"/login"} replace />;
  // }

  if (!loggedInUser) {
    return <Navigate to={"/login"} replace />;
  }

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/login", { replace: true });
  //   }
  // }, [isAuthenticated, navigate]);

  if (
    allowedRoles &&
    loggedInUser?.role &&
    !allowedRoles.includes(loggedInUser.role)
  ) {
    return <NotAuthorized />;
  }

  return <>{children}</>;
};

export default AuthGuard;
