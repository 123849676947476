import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./theme/index.css";
import "./locale/i18n";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./context";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./react-query";
import { ToastContainer } from "react-toastify";
import { ConfigProvider } from "antd";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider>
      <AuthContextProvider>
        <Suspense>
          <App />
        </Suspense>
        <ToastContainer />
      </AuthContextProvider>
    </ConfigProvider>
  </QueryClientProvider>
);

reportWebVitals();
