import { ThemeConfig } from "antd";

import { ThemeColorPresets } from "../../types/enum";

const customThemeTokenConfig: ThemeConfig["token"] = {
  colorSuccess: "#22c55e",
  colorWarning: "#ff7849",
  colorError: "#ff5630",
  colorInfo: "#00b8d9",

  wireframe: false,

  borderRadiusSM: 2,
  borderRadius: 2,
  borderRadiusLG: 2,
};

const customComponentConfig: ThemeConfig["components"] = {
  Breadcrumb: {
    fontSize: 10,
    separatorMargin: 2,
  },
  Menu: {
    fontSize: 12,
  },
};

const colorPrimarys: {
  [k in ThemeColorPresets]: string;
} = {
  default: "#2065D1",
  cyan: "#078DEE",
  purple: "#7635DC",
  blue: "#2065D1",
  orange: "#FDA92D",
  red: "#FF3030",
};

const themeModeToken: Record<"dark" | "light", ThemeConfig> = {
  dark: {
    token: {
      colorBgLayout: "#161c24",
      colorBgContainer: "#212b36",
      colorBgElevated: "#161c24",
    },
    components: {
      Modal: {
        headerBg: "#212b36",
        contentBg: "#212b36",
        footerBg: "#212b36",
      },
      Notification: {},
    },
  },
  light: {},
};

export {
  customThemeTokenConfig,
  customComponentConfig,
  colorPrimarys,
  themeModeToken,
};
