import BaseRoutes from "./base";
import { Fragment, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NotFound from "../modules/NotFound";
import AuthGuard from "./AuthGuard";
import { CircleLoading } from "../components/loading";
import AuthContextProvider from "../context";

interface RouteConfig {
  path: string;
  exact: boolean;
  component: React.ComponentType;
  useAuth: boolean;
  allowedRoles?: string[];
}

const RoutesWrapper = () => {
  return (
    <BrowserRouter>
      {/* <AuthContextProvider> */}
      <Routes>
        {(BaseRoutes as RouteConfig[]).map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Fragment>
                <Suspense fallback={<CircleLoading />}>
                  {route.useAuth ? (
                    <AuthGuard allowedRoles={route.allowedRoles}>
                      <route.component />
                    </AuthGuard>
                  ) : (
                    <route.component />
                  )}
                </Suspense>
              </Fragment>
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* </AuthContextProvider> */}
    </BrowserRouter>
  );
};

export default RoutesWrapper;
