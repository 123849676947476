import { useRefreshToken } from "../hooks/auth";
import { getDecodedJWT } from ".";
import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context";

const useTokenRefresh = () => {
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { logout } = useContext(AuthContext);
  const { mutate } = useRefreshToken();

  const scheduleTokenRefresh = () => {
    const decoded = getDecodedJWT();
    if (decoded) {
      const { exp } = decoded;
      const currentTime = Date.now() / 1000;
      const timeUntilExpiry = exp - currentTime;
      const refreshTime = (timeUntilExpiry - 1 * 60) * 1000; // Refresh 1 minute before expiry

      console.log("Current time:", currentTime);
      console.log("Token expiry time:", exp);
      console.log("Time until expiry:", timeUntilExpiry);
      console.log("Scheduled refresh time (ms):", refreshTime);

      if (refreshTime > 0) {
        if (refreshTimeoutRef.current) {
          clearTimeout(refreshTimeoutRef.current);
          console.log("Clearing existing timeout");
        }
        refreshTimeoutRef.current = setTimeout(async () => {
          try {
            console.log("Attempting to refresh token...");
            await mutate();
            console.log("Token refreshed successfully");
            scheduleTokenRefresh(); // Reschedule after refresh
          } catch (error) {
            console.error("Token refresh failed", error);
            logout();
          }
        }, refreshTime);
      } else {
        console.warn(
          "Refresh time is not valid, token might be expired already"
        );
      }
    } else {
    }
  };

  useEffect(() => {
    scheduleTokenRefresh();

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
        console.log("Clearing timeout on unmount");
      }
    };
  }, []);

  return null; // This hook does not render anything
};

export default useTokenRefresh;
