import { lazy } from "react";
import { PrivatePaths } from "./path";

const BaseRoutes = [
  {
    path: "/*",
    component: lazy(() => import("./AuthRouter")),
    useAuth: false,
    exact: true,
  },

  {
    path: `/${PrivatePaths.SADMIN}/*`,
    component: lazy(() => import("./SuperAdminRouter")),
    useAuth: true,
    allowedRoles: ["superAdmin"],
    exact: true,
  },
  {
    path: `/${PrivatePaths.ADMIN}/*`,
    component: lazy(() => import("./AdminRouter")),
    useAuth: true,
    allowedRoles: ["admin"],
    exact: true,
  },
  {
    path: `/${PrivatePaths.USER}/*`,
    component: lazy(() => import("./UserRouter")),
    useAuth: true,
    allowedRoles: ["user"],
    exact: true,
  },

  {
    path: "*",
    component: lazy(() => import("../modules/NotFound")),
    useAuth: false,
    exact: true,
  },

  {
    path: "/*",
    component: lazy(() => import("../modules/NotAuthorized")),
    useAuth: false,
    exact: true,
  },
];

export default BaseRoutes;
